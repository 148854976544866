<template>
	<div class='enterprise'>
			<div>
				<div v-show="visit" class='tips_top_box' style='padding-top:1px'>
					<p class="tips_titele_box"><span class="tips_title">使用指南</span> <span class="close_open"
							@click='visit=false'>关闭提示</span></p>
					<p> 1、您可以根据角色、身份设置员工标签，比如：财务、行政、领导；您也可以根据地域设置员工标签，比如：华南区、华北区。</p>
					<p> 2、设置后，在员工管理可为员工添加设置好的员工标签。</p>
				</div>
				<div style="text-align: right;" :class="!visit?'tips_top_box':'' ">
					<a-button type="primary" @click='visible=true'>
						添加标签
					</a-button>
				</div>
			</div>
			<TableList :columns="columns" :get_table_list="get_table_list" :slot_table_list="['op','icon']" ref="list">

				<template #op={record}>
					<div class="op">
						<div class="cq_color">
							<span class="cq_color" @click='edit(record)'>编辑</span>
							<span style='color:#eee'> | </span>
							<span class="cq_color" @click='add_merge(record)'>合并</span>
							<span style='color:#eee'> | </span>
							<a-popconfirm title="是否确定删除该标签?" ok-text="是" cancel-text="否" @confirm="confirm(record)"
								@cancel="cancel">
								<span class="cq_error" v-if='record.user_num==0'>删除</span>
							</a-popconfirm>
						</div>
					</div>
				</template>

			</TableList>
			<a-modal v-model:visible="visible" :title="id?'编辑员工标签':'新增员工标签' " @ok="add_ok">
				<a-form-item required :labelCol='{span:5}' :rules="[{ required: true, message: '请输入标签名称', trigger: 'blur' }]"
					label="标签名称" name="username">
					<a-input style="width:350px" v-model:value="username" />
				</a-form-item>
			</a-modal>
			<a-modal v-model:visible="mervisible" title="合并" @ok="mer_ok">
				<a-form-item :labelCol='{span:5}' label="当前标签名称">{{mer_info.name}}</a-form-item>
				<a-form-item required :labelCol='{span:5}' label="合并入"
					:rules="[{ required: true, message: '请选择合并入的标签', trigger: 'blur' }]">
					<a-select style="width:300px" ref="select" v-model:value="mer_info.target_id">
						<a-select-option :value="item.id" :disabled="mer_info.source_id == item.id"
							v-for="(item,index) in label_list" :key="index">{{item.name}}</a-select-option>
					</a-select>
				</a-form-item>
			</a-modal>
	</div>
</template>

<script>
	import TableList from "@/components/TableList";
	import {
		labelList,
		modifyLabel,
		mergeLabel,
		removeLabel,
		labelSort,labelList2
	} from '@/api/organization.js'
	import {
		message
	} from 'ant-design-vue';
	export default {
		components: {
			TableList
		},
		data() {
			return {
				id: '',
				visit: true,
				search_form: false,
				mervisible: false,
				username: '',
				get_table_list: labelList,
				mer_info: {},
				columns: [{
						title: '员工标签',
						dataIndex: 'name'
					},
					{
						title: '在职员工数',
						dataIndex: 'user_num',

					},
					{
						title: '操作',
						dataIndex: 'op',
						width: '170px',
						scopedSlots: {
							customRender: 'op'
						}
					},
				],
				label_list: [],
				del_id: null, // 即将删除的规则ID
				visible: false,
			}
		},
		created() {
			this.get_list()
		},
		unmounted() {},
		methods: {
			edit(item) {
				this.id = item.id
				this.username = item.name
				this.visible = true
			},
			add_merge(item) {
				this.mer_info = {
					source_id: item.id,
					name: item.name,
					target_id: ''
				}
				this.mervisible = true
			},
			get_list() {
				labelList2({
					data: {
						is_page: -1
					}
				}).then(res => {
					this.label_list = res.data.list
				})
			},
			unAble(item) {
				let status = item.status;
				changeRule({
					data: {
						id: item.id,
						status: item.status ? 0 : 1
					},
					info: true
				}).then(res => {
					item.status = status ? 0 : 1;
				})
			},
			confirm(item) {
				removeLabel({
					data: {
						id: item.id
					},
					info: true
				}).then(res => {
					this.$refs.list.get_list();
				})
			},

			mer_ok() {
				mergeLabel({
					data: this.mer_info,
					info: true
				}).then(res => {
					this.$refs.list.get_list();
					this.mervisible = false;
				})
			},
			add_ok() {
				if (!this.username) {
					message.error('请填写员工标签名称')
				}
				modifyLabel({
					data: {
						name: this.username,
						id:this.id?this.id:''
					},
					info: true
				}).then(res => {
					this.id=''
					this.username = ''
					this.$refs.list.get_list();
					this.visible = false;
				})
			},
			del(id) {
				this.del_id = id;
				this.visible = true;
			},
			toAdd(id) {
				this.$router.push({
					path: 'add',
					query: {
						id
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	@import url("../../assets/less/app.less");
	@import 'info.less';
	.enterprise{
		background:#fff;
	}
	.table_list_body {
		margin: 0px;
		padding: 0 24px;
	}

	.tips_top_box {
		padding-top: 16px;
	}

	/deep/.page_content {
		padding: 0px;
		margin: 0px;
	}
	.tips_top_box{
		.close_open{
			margin-left: 10px;
			color:#fff;
		}
	}
	.tips_top_box:hover {
		.close_open{
			color:@cq_color;
		}
	}
	.tips_titele_box{
		margin-top:0px;
	}
</style>